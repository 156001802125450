const permissions = {
  admin: ["*", "!view:allDepartments"],
  organizationAdmin: [
    "view:allAttendee",
    "verify:attendee",
    "view:allDepartments",
    "view:classes",
    "view:shifts",
    "view:devices",
    "view:holidays",
  ],
};

export default permissions;
