import {
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  colors,
} from "@mui/material";

import { ArrowDownward, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useApp from "../../hooks/useApp";
import usePermissions from "../../hooks/usePermissions";
import menu from "./menu";

function SideBarList({ collapsed }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  return (
    <Box sx={{ p: 2 }}>
      {Object.entries(menu).map(([title, list]) => (
        <Stack key={title}>
          {!collapsed && (
            <Typography
              variant="subtitle2"
              color={colors.blueGrey[400]}
              textTransform="capitalize"
            >
              {t(title)}
            </Typography>
          )}
          <MenuList>
            {list.map((menuItem) => {
              if (menuItem?.permissions) {
                const isPermitted = hasPermission(menuItem?.permissions);
                if (!isPermitted) return null;
              }
              if (menuItem?.submenus) {
                return (
                  <MenuList key={menuItem.key}>
                    {menuItem?.submenus?.map((subMenu) => (
                      <MenuItem
                        key={subMenu.key}
                        selected={subMenu.path === location.pathname}
                        onClick={() => navigate(subMenu.path)}
                        sx={{
                          ...(collapsed && {
                            ".MuiListItemIcon-root": {
                              minWidth: "max-content",
                            },
                          }),
                        }}
                      >
                        <ListItemIcon>
                          <Badge
                            badgeContent={subMenu?.badge || 0}
                            color="primary"
                          >
                            <subMenu.icon color="#78909c" />
                          </Badge>
                        </ListItemIcon>
                        {!collapsed && (
                          <ListItemText
                            primary={
                              subMenu?.title?.i18n
                                ? t(subMenu.title.i18n)
                                : subMenu.title
                            }
                          />
                        )}
                        <IconButton size="small">
                          <ArrowDownward />
                        </IconButton>
                      </MenuItem>
                    ))}
                  </MenuList>
                );
              }
              return (
                <MenuItem
                  key={menuItem.key}
                  selected={menuItem.path === location.pathname}
                  onClick={() => navigate(menuItem.path)}
                  sx={{
                    ...(collapsed && {
                      ".MuiListItemIcon-root": { minWidth: "max-content" },
                    }),
                  }}
                >
                  <ListItemIcon>
                    <Badge badgeContent={menuItem?.badge || 0} color="primary">
                      <menuItem.icon color="#78909c" />
                    </Badge>
                  </ListItemIcon>
                  {!collapsed && (
                    <ListItemText
                      primary={
                        menuItem?.title?.i18n
                          ? t(menuItem.title.i18n)
                          : menuItem.title
                      }
                    />
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        </Stack>
      ))}
    </Box>
  );
}

export default function Sidebar() {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("attendance_sidebar_collapsed") === "true"
  );
  const app = useApp();

  function handleChangeCollapsed() {
    localStorage.setItem("attendance_sidebar_collapsed", !collapsed);
    setCollapsed(!collapsed);
  }

  return (
    <Stack
      sx={{
        ...(collapsed ? { flexShrink: 1 } : { flexBasis: "20%" }),
        height: "100vh",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
      borderRight={1}
      borderColor={"grey.300"}
      position={"relative"}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        position={"sticky"}
        bgcolor={"background.default"}
        border={1}
        borderColor={"grey.300"}
        zIndex={10}
        top={0}
        gap={1}
        py={2}
        pb={3}
      >
        <img
          src={app?.user?.organization?.image || "/logo.png"}
          width={36}
          alt="brand"
        />
        {!collapsed && (
          <Typography color="primary" fontWeight={700} textAlign="center">
            {t("attendance")}
          </Typography>
        )}
        <IconButton
          onClick={handleChangeCollapsed}
          sx={{
            position: "absolute",
            right: "0",
            top: "100%",
            transform: "translateY(-50%)",
            backgroundColor: "background.paper",
            border: "1px solid",
            borderColor: "grey.300",
            padding: 0,
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          {collapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Stack>
      <Stack>
        <SideBarList collapsed={collapsed} />
      </Stack>
    </Stack>
  );
}
