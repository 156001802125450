import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getAttendeeMutationOptions } from "../../../../../api/mutationOptions";
import { getAttendeeQueryOptions } from "../../../../../api/queryOptions";
import TableActions from "../../../../../components/TableActions";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import List from "../../../../../layouts/List";
import nepaliNumberConverter from "../../../../../utils/convertToNepaliNumber";
export default function StudentList() {
  const { t } = useTranslation();
  const { deptId, classId } = useParams();
  const classname = useUrlQuery("classname");
  const navigate = useNavigate();

  const [organization] = useUrlQuery(["organization"]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        header: t("name"),
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar
                alt={row.original?.first_name + " " + row.original?.last_name}
                src={row.original.image}
              />
              <Stack>
                <Typography>
                  {row.original?.first_name + " " + row.original?.last_name}
                </Typography>
                <Typography variant="body2">{row.original?.roll_no}</Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        accessorKey: "card_number",
        header: t("cardNo"),
      },
      {
        accessorKey: "phone",
        header: t("phone"),
      },
      {
        accessorKey: "date_of_birth",
        cell: ({ row }) => (
          <>
            <Typography component="p" variant="body">
              वि. स. {nepaliNumberConverter(row?.original?.date_of_birth_bs)}
            </Typography>
            <Typography component="p" variant="body">
              {row?.original?.date_of_birth_ad} AD
            </Typography>
          </>
        ),
        header: t("dateOfBirth"),
      },
      {
        accessorKey: "address",
        header: t("address"),
      },
      {
        accessorKey: "status",
        header: t("status"),
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: t("actions"),
        cell: ({ row }) => (
          <TableActions
            actions={{
              edit: {
                onClick: () =>
                  navigate(
                    `/management/department/${
                      deptId ?? row.original.department
                    }/attendee/edit/${row.original.id}?role=student`
                  ),
              },
              delete: {
                mutationOptionFn: getAttendeeMutationOptions,
                onClick: (mutate) => {
                  mutate &&
                    mutate({
                      mutationMode: "delete",
                      role: "student",
                      id: row.original.id,
                    });
                },
              },
              // view: {},
            }}
          />
        ),
      },
    ],
    [t, navigate, deptId]
  );

  return (
    <Box p={2}>
      <List
        title={() => {
          if (classId) return `${classname} Students`;
          return t("students");
        }}
        columns={columns}
        create={[
          {
            id: "createStudent",
            title: "createStudent",
            path: `/management/department/${deptId}/attendee/create?role=student&organization=${organization}`,
          },
        ]}
        queryOptionFn={getAttendeeQueryOptions}
        queryState={{ role: "student", department: deptId, class: classId }}
      />
    </Box>
  );
}
