import { getStorage } from "../config/localStorage";
import permissions from "../config/permissions";

const { createContext, useReducer, useEffect, useCallback } = require("react");

const initialState = {
  role: null,
  permissions: [],
};

const PermissionContext = createContext({
  role: null,
  permissions: [],
  hasPermission: () => true,
});

function permissionReducer(state, action) {
  switch (action.type) {
    case "SET_ROLE_AND_PERMISSION":
      return {
        ...state,
        permissions: action.payload.permissions,
        role: action.payload.role,
      };
    default:
      return state;
  }
}

export function PermissionProvider({ children }) {
  const [state, dispatch] = useReducer(permissionReducer, initialState);

  const setRoleAndPermissions = useCallback(
    (r = null) => {
      let role = r || getStorage("role");
      let rolePermissions = permissions[role] || [];

      dispatch({
        type: "SET_ROLE_AND_PERMISSION",
        payload: { role, permissions: rolePermissions },
      });
    },
    [dispatch]
  );

  const hasPermission = (expectedPermission = "") => {
    if (!expectedPermission) return true;
    if (
      expectedPermission[0] === "!" &&
      state?.permissions?.includes?.[expectedPermission.slice(1)]
    )
      return false;
    if (state.permissions.includes("*")) return true;
    return state.permissions.includes(expectedPermission);
  };

  useEffect(() => {
    setRoleAndPermissions();
  }, [setRoleAndPermissions]);

  return (
    <PermissionContext.Provider
      value={{ ...state, hasPermission, setRoleAndPermissions }}
    >
      <>{children}</>
    </PermissionContext.Provider>
  );
}

export default PermissionContext;
