import { ChevronLeft, Close } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../components/Table";
import usePermissions from "../../hooks/usePermissions";

export default function List({
  title = "",
  columns = [],
  create,
  otherButtons = [],
  queryOptionFn,
  transformData = null,
  withBackButton = false,
  filters = [],
  queryState = {},
  tableSize,
  manualPagination = false,
  clearFilter = null,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { hasPermission } = usePermissions();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { isLoading, data } = useQuery({
    enabled: !!queryOptionFn,
    ...(queryOptionFn && {
      ...queryOptionFn({
        navigate,
        params,
        queryState: {
          ...queryState,
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
        },
      }),
    }),
  });

  function onCreateButtonClicked(index) {
    return () => {
      if (Array.isArray(create)) {
        create[index]?.onClick && create[index]?.onClick();
        navigate(create[index].path);
      } else if (create) {
        create?.onClick && create.onClick();
        navigate(create.path);
      }
    };
  }
  return (
    <Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h5" color="primary">
          {withBackButton && (
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft color="primary" />
            </IconButton>
          )}
          {typeof title === "function" ? title() : t(title)}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          {Array.isArray(otherButtons) && otherButtons}
          {create && Array.isArray(create)
            ? create.map((item, index) =>
                hasPermission(item?.permissions || []) ? (
                  <Button
                    key={item?.id || index}
                    variant="contained"
                    onClick={onCreateButtonClicked(index)}
                    {...item}
                  >
                    {t(item.title)}
                  </Button>
                ) : null
              )
            : create &&
              hasPermission(create?.permissions || []) && (
                <Button
                  variant="contained"
                  onClick={onCreateButtonClicked()}
                  {...create}
                >
                  {t(create.title)}
                </Button>
              )}
        </Stack>
      </Stack>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
        alignItems={"center"}
      >
        {filters.length > 0 && filters}
        {filters.length > 0 && clearFilter && (
          <Box
            px={1}
            sx={{
              gridColumn: {
                xs: "1",
                sm: "2",
                md: "4",
                lg: "5",
              },
            }}
          >
            <Button
              variant="contained"
              color="error"
              startIcon={<Close />}
              onClick={clearFilter}
              fullWidth
            >
              Clear Filter
            </Button>
          </Box>
        )}
      </Box>
      <Table
        size={tableSize}
        isLoading={isLoading}
        data={
          (transformData ? transformData(data) : data?.data?.data?.results) ||
          []
        }
        columns={columns}
        manualPagination={!!manualPagination}
        manualPaginationState={{
          pagination,
          setPagination,
          rowCount: data?.data?.data?.count,
        }}
      />
    </Stack>
  );
}
