/**
 * @module hocs/withPermission
 */

import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import usePermissions from "../hooks/usePermissions";

/**
 * @function withPermission(permissions)
 *
 * @description A Higher Order Component that checks if the user has the required permissions
 * before rendering the wrapped component.
 *
 * @param {string | string[]} permissions - The required permissions.
 *
 * @returns {React.FC} A Higher Order Component that checks for the required permissions.
 *
 * @example
 * import withPermission from "./withPermission";
 *
 * const MyComponent = () => {
 *   // ...
 * };
 *
 * export default withPermission(["view:users"])(MyComponent);
 */
export default function withPermission(expectedPermission, Component) {
  return (props) => {
    const { hasPermission } = usePermissions();
    let permitted = useMemo(
      () => hasPermission(expectedPermission),
      [hasPermission]
    );
    if (permitted) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/" />;
    }
  };
}
