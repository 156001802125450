import { Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  readClasses,
  readDepartments,
  readShifts,
  readUser,
} from "../../../../../api";
import { getAttendeeMutationOptions } from "../../../../../api/mutationOptions";
import FormElement from "../../../../../components/form";
import withHookForm from "../../../../../hocs/withHookForm";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import queryGenerator from "../../../../../utils/queryGenerator";
import { attendeeSchema } from "../../../../../validations/schema";

function AttendeeFormElement({
  register,
  formState: { errors },
  setValue,
  watch,
}) {
  const { t } = useTranslation();
  const role = useUrlQuery("role");
  const { deptId, id } = useParams();
  const isClassTeacher = watch("isClassTeacher");
  const [organization] = useUrlQuery(["organization"]);

  const { data, isLoading } = useQuery({
    queryKey: ["attendee", id],
    queryFn: readUser(id),
    enabled: !!id,
  });

  const { data: shiftData, isLoading: isShiftLoading } = useQuery({
    queryKey: ["shifts", organization],
    queryFn: readShifts(queryGenerator({ organization })),
    staleTime: Infinity,
  });

  const { data: deptData, isLoading: isDeptLoading } = useQuery({
    queryKey: ["departments"],
    queryFn: readDepartments,
  });

  const { data: classData, isLoading: isClassLoading } = useQuery({
    queryKey: ["classes", organization],
    queryFn: readClasses(queryGenerator({ organization })),
  });

  const shiftOptions = useMemo(() => {
    if (isShiftLoading) return [];
    let options = shiftData?.data?.data?.results?.map((item) => ({
      label: item?.title,
      value: item?.id,
    }));

    return options || [];
  }, [shiftData, isShiftLoading]);

  const basicDetail = useMemo(
    () => [
      {
        id: "first_name",
        label: t("firstName"),
        type: "text",
        required: true,
        defaultValue: data?.data?.data?.first_name,
      },

      {
        id: "last_name",
        label: t("lastName"),
        type: "text",
        required: true,
        defaultValue: data?.data?.data?.last_name,
      },
      {
        id: "email",
        label: t("email"),
        type: "email",
        required: true,
        defaultValue: data?.data?.data?.email,
      },
      {
        id: "phone",
        label: t("phone"),
        type: "tel",
        required: true,
        defaultValue: data?.data?.data?.phone,
      },
      {
        id: "image",
        label: t("uploadImage"),
        type: "file",
        required: true,
      },
      {
        id: "validity",
        label: t("validity"),
        type: "date_np",
        required: true,
        defaultValue: dayjs(new Date()).format("YYYY-MM-DD"),
        setValue: setValue,
      },
      {
        id: "card_number",
        label: t("cardNo"),
        type: "text",
        required: true,
        defaultValue: data?.data?.data?.card_number,
      },
      {
        id: "shift",
        label: t("shift"),
        type: "select",
        required: true,
        options: shiftOptions,
        defaultValue: data?.data?.data?.shift,
        isLoading: isShiftLoading,
      },
    ],
    [data, t, isShiftLoading, shiftOptions]
  );

  const studentForm = useMemo(
    () =>
      isClassLoading
        ? []
        : [
            {
              id: "grade",
              label: t("grade"),
              type: "select",
              options:
                classData?.data?.data?.results?.map((c) => ({
                  value: c.id,
                  label: c.name,
                })) || [],
              defaultValue: data?.data?.data?.grade,
            },
            {
              id: "roll_no",
              label: t("rollNo"),
              type: "text",
              defaultValue: data?.data?.data?.roll_no,
            },

            {
              id: "date_of_birth_ad",
              label: t("dateOfBirth"),
              type: "date_np",
              setValue: setValue,
              defaultValue: dayjs(new Date()).format("YYYY-MM-DD"),
            },
            {
              id: "address",
              label: t("address"),
              type: "address",
              defaultValue: data?.data?.data?.address,
            },
            {
              id: "father_name",
              label: t("fatherName"),
              type: "text",
              defaultValue: data?.data?.data?.father_name,
            },
            {
              id: "mother_name",
              label: t("motherName"),
              type: "text",
              defaultValue: data?.data?.data?.mother_name,
            },
            {
              id: "guardian_name",
              label: t("guardianName"),
              type: "text",
              defaultValue: data?.data?.data?.guardian_name,
            },

            {
              id: "guardian_phone",
              label: t("guardianPhone"),
              type: "tel",
              defaultValue: data?.data?.data?.guardian_phone,
            },
            {
              id: "guardian_email",
              label: t("guardianEmail"),
              type: "email",
              defaultValue: data?.data?.data?.guardian_email,
            },
          ],
    [data, t, classData, isClassLoading]
  );

  const teacherForm = useMemo(
    () => [
      {
        id: "username",
        label: t("username"),
        type: "text",
        defaultValue: data?.data?.data?.username,
        disabled: !!id,
      },
      {
        id: "password",
        label: t("password"),
        type: "password",
      },
      {
        id: "isClassTeacher",
        label: t("isClassTeacher"),
        type: "switch",
        defaultChecked: data?.data?.data?.isClassTeacher,
      },
      ...(isClassTeacher
        ? [
            {
              id: "grade",
              label: t("grade"),
              type: "select",
              options: classData?.data?.data?.results?.map((c) => ({
                value: c.id,
                label: c.name,
              })),
              defaultValue: data?.data?.data?.grade,
              isLoading: isClassLoading,
            },
          ]
        : []),
    ],
    [classData, t, isClassLoading, data, isClassTeacher, id]
  );

  useEffect(() => {
    if (role) setValue("role", role);
    if (deptId) setValue("department", deptId);
    if (organization) setValue("organization", organization);
  }, [setValue, role, deptId, organization]);

  useEffect(() => {
    if (data?.data?.data?.isClassTeacher) setValue("isClassTeacher", true);
    if (data?.data?.data?.shift)
      setValue("shift.value", data?.data?.data?.shift);
    if (data?.data?.data?.grade)
      setValue("grade.value", data?.data?.data?.grade);
  }, [data, setValue]);

  return (
    <Stack>
      {!isLoading && (
        <Stack rowGap={4}>
          <Grid2 container>
            <Grid2 sm={12}>
              <Typography variant="body1">{t("basicDetail")}</Typography>
            </Grid2>
            {basicDetail?.map((item) => (
              <Grid2 sm={3} key={item.id}>
                <AttendeeFieldElement
                  {...item}
                  register={register}
                  setValue={setValue}
                />
              </Grid2>
            ))}
            {!isDeptLoading && !deptId && (
              <Grid2 sm={3}>
                <FormElement.Select
                  label={t("department")}
                  id="department"
                  options={deptData?.data?.data?.results?.map((dept) => ({
                    value: dept.id,
                    label: dept.name,
                  }))}
                  setValue={setValue}
                  required
                />
              </Grid2>
            )}
          </Grid2>
          {role === "student" && (
            <Grid2 container>
              <Grid2 sm={12}>
                <Typography variant="body1">{t("studentDetail")}</Typography>
              </Grid2>
              {studentForm?.map((item) => (
                <Grid2 sm={3} key={item.id}>
                  <AttendeeFieldElement
                    {...item}
                    register={register}
                    setValue={setValue}
                    error={errors?.[item.id]?.message}
                  />
                </Grid2>
              ))}
            </Grid2>
          )}
          {role === "teacher" && (
            <Grid2 container>
              <Grid2 sm={12}>
                <Typography variant="body1">{t("teacherDetail")}</Typography>
              </Grid2>
              {teacherForm?.map((item) => (
                <Grid2 sm={3} key={item.id}>
                  <AttendeeFieldElement
                    {...item}
                    register={register}
                    setValue={setValue}
                    error={errors?.[item.id]?.message}
                  />
                </Grid2>
              ))}
            </Grid2>
          )}
        </Stack>
      )}
    </Stack>
  );
}

function AttendeeFieldElement({ type, ...rest }) {
  switch (type) {
    case "text":
    case "date":
    case "number":
    case "email":
    case "tel":
    case "address":
    case "password":
      return <FormElement.Text type={type} {...rest} />;
    case "date_np":
      return <FormElement.Date {...rest} />;
    case "file":
      return <FormElement.File {...rest} />;
    case "select":
      return <FormElement.Select {...rest} />;
    case "switch":
      return <FormElement.Switch {...rest} />;
    default:
      return null;
  }
}

function transformData(data) {
  try {
    let body = { ...data };
    if (data.date_of_birth_ad) {
      body.date_of_birth_bs = body.date_of_birth_ad;
    }

    body.shift = body.shift?.value;
    if (body?.department && typeof body?.department !== "string") {
      body.department = data.department.value;
    }
    if (data?.grade) {
      body.grade = data?.grade?.value;
    }
    if (
      data?.password === null ||
      data?.password === undefined ||
      data?.password === ""
    ) {
      delete body.password;
    }

    const formData = new FormData();
    Object.keys(body).forEach((key) => {
      if (key === "image") {
        formData.append(key, body[key][0]);
      } else {
        formData.append(key, body[key]);
      }
    });
    return { formData, role: body.role };
  } catch (error) {}
}

const AttendeeForm = withHookForm({
  title: "createAttendee",
  submitText: "createAttendee",
  validationSchema: attendeeSchema(),
  mutationOptionFn: getAttendeeMutationOptions,
  transformData,
})(AttendeeFormElement);

const AttendeeUpdateForm = withHookForm({
  title: "updateAttendee",
  submitText: "updateAttendee",
  validationSchema: attendeeSchema(),
  mutationOptionFn: getAttendeeMutationOptions,
  transformData,
})(AttendeeFormElement);

const AttendeeVerifyForm = withHookForm({
  title: "verifyAttendee",
  submitText: "verifyAttendee",
  validationSchema: attendeeSchema(),
  mutationOptionFn: getAttendeeMutationOptions,
  transformData,
  mutationState: { mutationMode: "verify" },
})(AttendeeFormElement);

export { AttendeeUpdateForm, AttendeeVerifyForm };

export default AttendeeForm;
